<template>
    <div v-loading.fullscreen.lock="loading" class="bg-white w-screen h-screen">
        <div class="flex items-center h-full relative">
            <div
                class="w-[90%] tablet:w-fit absolute bottom-2 -translate-x-1/2 left-1/2"
            >
                <p
                    class="text-left tablet:text-center font-semibold text-base text-brand-atosa"
                >
                    {{ $t('page.login.join_course') }}
                    <a href="https://www.quangcaoshopee.com/" target="_blank">
                        <span class="underline">
                            {{ $t('page.login.here') }}
                        </span>
                    </a>
                </p>
                <p
                    class="text-left tablet:text-center font-semibold text-base text-brand-atosa"
                >
                    {{ $t('page.login.learn_more') }}
                    <a :href="s__website" target="_blank">
                        <span class="underline">
                            {{ $t('page.login.here') }}</span
                        >
                    </a>
                </p>
            </div>

            <div class="absolute top-1/2 -translate-y-1/2 left-4 text-bg w-fit">
                <p class="uppercase font-semibold text-[7rem]">ataME</p>
                <p
                    class="text-lg text-border uppercase font-semibold break-words text-center"
                >
                    {{ $t('page.login.ads_management_toolkit') }}
                </p>
            </div>

            <div
                class="w-[60%] z-10 bg-transparent util-flex-center flex-col z-10"
            >
                <img
                    class="object-cover"
                    :src="require('@/assets/images/bg_tiktok_ads.png')"
                    alt=""
                />

                <el-carousel
                    class="w-full mx-auto h-fit"
                    :interval="2000"
                    arrow="never"
                    indicator-position="none"
                >
                    <el-carousel-item
                        v-for="item in p__seo_keywords"
                        :key="item"
                    >
                        <div class="w-full">
                            <p
                                class="text-lg text-border uppercase font-semibold break-words text-center"
                            >
                                {{ item }}
                            </p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="flex-1 z-10">
                <el-form
                    ref="rule_form"
                    :rules="rules"
                    :model="register_form.form"
                    label-position="left"
                    class="w-screen tablet:w-[480px] bg-white border-border border-[1px] shadow-lg py-6 px-4 tablet:py-12 tablet:px-8 tablet:rounded-xl"
                >
                    <div class="util-flex-center">
                        <img
                            class="w-11 h-11"
                            :src="require('@/assets/images/logo_brand.png')"
                            alt=""
                        />
                        <p
                            class="font-semibold text-brand-atosa uppercase text-3xl ml-3"
                        >
                            ATAME
                        </p>
                    </div>

                    <p
                        class="text-center text-brand-atosa font-semibold text-xl uppercase my-6"
                    >
                        {{ $t('page.login.ads_management_toolkit') }}
                    </p>

                    <p
                        v-if="user_info"
                        class="mb-4 text-md leading-5"
                        v-html="
                            $t('page.login.reset_password', {
                                username: user_info.username,
                                display_name: user_info.display_name
                            })
                        "
                    ></p>

                    <el-form-item prop="password">
                        <el-input
                            ref="password"
                            v-model="register_form.form.password"
                            :type="password_type"
                            :placeholder="$t('page.login.placeholder.password')"
                            name="password"
                            tabindex="2"
                            auto-complete="off"
                            show-password
                            clearable
                            @keyup.enter.native="submitForm()"
                        />
                    </el-form-item>

                    <el-button
                        :loading="loading"
                        type="primary"
                        icon="el-icon-position"
                        class="w-full mt-8"
                        @click="submitForm"
                        >{{ $t('button.send_request') }}
                    </el-button>

                    <div class="mt-5 flex text-sm justify-center space-x-2">
                        <span> {{ $t('page.login.have_an_account') }}? </span>
                        <router-link to="/login">
                            <p class="text-brand-atosa font-medium">
                                {{ $t('page.login.login_now') }}
                            </p>
                        </router-link>
                    </div>

                    <div class="flex mt-4">
                        <el-dropdown class="ml-auto" trigger="click">
                            <div>
                                <img
                                    class="w-7 h-4"
                                    :src="selected_language.image"
                                    alt=""
                                />
                            </div>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="(
                                        item, index
                                    ) in p__list_select_languages"
                                    :key="index"
                                    :class="{
                                        'is-active':
                                            item.code === selected_language.code
                                    }"
                                    @click.native="handleTranslate(item.code)"
                                >
                                    <div class="flex items-center space-x-2">
                                        <img
                                            class="w-5 h-3"
                                            :src="item.image"
                                            alt=""
                                        />

                                        <p>{{ item.description }}</p>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </el-form>
            </div>
        </div>
        <el-dialog
            :visible.sync="show_dialog.register_successfully"
            width="650px"
            class="el-dialog-center"
            :append-to-body="true"
            :close-on-click-modal="true"
        >
            <div>
                <div class="text-lg leading-[2rem] text-center font-medium">
                    {{ $t('page.login.message_for_reset_password') }}:
                    <br />
                    {{ register_form.form.email }}
                </div>
                <div class="mt-8 text-center">
                    <el-button
                        type="primary"
                        size="medium"
                        class="uppercase"
                        @click="$router.push('/login')"
                    >
                        {{ $t('page.login.login_now') }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { resetPassword, verifyTokenForgetPassword } from '@/services/atosa/auth'

export default {
    data() {
        return {
            selected_language: this.p__list_select_languages[0],
            rules: {
                password: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'change'
                    }
                ],
                confirm_password: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'change'
                    }
                ]
            },
            loading: false,
            password_type: 'password',
            redirect_url: `${window.location.href}`,
            redirect: undefined,
            user_info: null,
            register_form: {
                loading: false,
                form: {
                    ip: '0.0.0.0',
                    captcha: '',
                    response: '',
                    captcha_version: 'v3',
                    password: '',
                    token: ''
                }
            },

            ref: undefined,
            rkey: undefined,

            show_dialog: {
                register_successfully: false
            }
        }
    },

    computed: {
        s__website() {
            return this.$store.getters.app.s__website
        },

        s__end_domain() {
            return this.$store.getters.app.s__end_domain
        }
    },

    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true
        }
    },

    async mounted() {
        this.verifyTokenForgetPassword()

        if (typeof this.$route.query.ref !== 'undefined') {
            this.ref = this.$route.query.ref
        }
        if (typeof this.$route.query.rkey !== 'undefined') {
            this.rkey = this.$route.query.rkey
        }

        localStorage.removeItem('user_info')
        localStorage.removeItem('token')

        const locale = this.$i18n.locale

        if (locale) {
            const temp = this.p__list_select_languages.find(
                (language) => language.code === locale
            )
            if (temp) {
                this.selected_language = temp
            }
        }
    },

    methods: {
        async verifyTokenForgetPassword() {
            const token = this.$route.query.token
            if (token) {
                const response = await verifyTokenForgetPassword(token)

                if (response.error === 0) {
                    this.user_info = response.data
                    return
                }
            }

            this.$router.push({
                path: `login`
            })
        },

        async m__reload_recaptcha() {
            this.register_form.form.response =
                await this.m__get_recaptcha_token()
            this.register_form.form.captcha = this.register_form.form.response
        },

        m__get_recaptcha_token() {
            return new Promise((resolve) => {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(async () => {
                    // eslint-disable-next-line no-undef
                    const token = await grecaptcha.execute(
                        '6Lf3isIaAAAAAEEx5IBmKbf0Zdef-jGA8unA2XdB',
                        { action: 'validate_captcha' }
                    )
                    resolve(token)
                })
            })
        },

        submitForm() {
            this.$refs['rule_form'].validate((valid) => {
                if (valid) {
                    this.handleRegister()
                }
            })
        },

        async handleRegister() {
            this.loading = true

            await this.m__reload_recaptcha()

            try {
                await resetPassword({
                    ...this.register_form.form,
                    token: this.$route.query.token
                })
                this.show_dialog.register_successfully = true
                // this.p__showNotify('success', `Đăng ký thành công. Vui lòng kiểm tra email của bạn.`)
                // this.$router.push('/login')
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
                await this.m__reload_recaptcha()
            }
        },

        handleTranslate(code) {
            localStorage.setItem('code_lang', code)

            const url = window.location.href
            const urlParts = url.split('?')
            if (urlParts.length > 1) {
                const queryString = urlParts[1]
                const params = new URLSearchParams(queryString)

                if (params.has('lang')) {
                    params.delete('lang')
                    const newQueryString = params.toString()

                    const newURL =
                        urlParts[0] +
                        (newQueryString ? '?' + newQueryString : '')

                    window.history.replaceState({}, document.title, newURL)
                }
            }

            window.location.reload(true)
        }
    }
}
</script>

<style>
.el-carousel__container {
    height: 50px !important;
}
</style>
